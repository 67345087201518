<template lang="pug">
include ../../../configs/template
div.w-100.d-flex.flex-wrap
  div.w-100
    +select('body.status_document', 'listStatuses', 'status', 'labelName')
  div.w-100.mt-3
    v-btn(@click="saveStatus" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'SailorCertificationEditStatus',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        status_document: this.sailorDocument.status_document
      }
    }
  },
  computed: {
    ...mapGetters(['statusById']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      isLoading: state => state.sailor.isLoading
    }),
    listStatuses () {
      const statusesArray = []
      let statusesArrayIDs = [17, 18, 19, 21, 37]
      statusesArrayIDs.forEach(status => statusesArray.push(this.statusById(status)))
      return statusesArray
    }
  },
  methods: {
    ...mapActions(['updateCertificateEtiById']),
    saveStatus () {
      const body = { ...this.body }
      this.updateCertificateEtiById({ ...this.$route.params, body })
    }
  }
}

</script>
